import React from 'react';

import { Link } from 'react-router-dom';
import Container from 'reactstrap/lib/Container';

const Prefooter: React.FC = () => {
  return (
    <Container id="prefooter" className="d-print-none" fluid>
      <Container>
        <h3>Mailingliste</h3>
        <p>
          Trag dich ein und krieg als Erstes Tickets, sobald welche verfügbar sind. Garantiert nie Spam.
        </p>
        <p>
          <Link to="/newsletter">Jetzt für die Mailingliste anmelden</Link>
        </p>
      </Container>
    </Container>
  );
};

export default Prefooter;
